$brand-primary: #ED1D24;
$brand-secondary: #05275B;
$brand-attention: #FFA500;
$gray: #555;
$black: #000;
$white: #fff;

$width-mobile: 480px;
$width-tablet: 768px;
$width-desktop: 1200px;

$padding-small: 1rem;
$padding-medium: 2rem;
$padding-big: 3rem;

$border-radius: 1rem;

@media screen and (min-width: $width-tablet) {
  $padding-small: 1rem;
  $padding-medium: 2rem;
  $padding-big: 3rem;
}

html {
  font-size: 16px;
  @media screen and (min-width: $width-tablet) {
    font-size: 20px;
  }
}

body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  font-family: 'Jost', sans-serif;
}

a {
  text-decoration: none;
  color: $brand-primary;

}

img {
  max-width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h4 {
  line-height: 1.2;
  color: $brand-secondary;
  text-transform: uppercase;
}

h1 {
  font-size: 3em;
  margin-bottom: 1rem;
}

h2 {
  font-size: 2em;
  margin-bottom: 1rem;
  font-weight: 600;
}

h3 {
  font-size: 1.3em;
  margin-bottom: 1rem;
  font-weight: 700;
}

h4 {
  font-size: 1.1em;
  margin-bottom: 1rem;
}

.btn {
  display: inline-block;
  text-decoration: none;
  padding: 1rem 1.5rem;
  background-color: $brand-primary;
  font-size: 1.2rem;
  color: $white;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  border: none;
  cursor: pointer;
  transition: background-color 300ms ease;
  border-radius: 50px;

  &:hover {
    background-color: darken($brand-primary, 20%);
    text-decoration: none;
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  &.vertical-top {
    align-items: flex-start;
  }
  
  gap: $padding-medium;

  @media screen and (max-width: $width-mobile) {
    flex-direction: column;
    .col {
      width: 100%;
    }
  }

  .col {
    max-width: 100%;
    flex: 1; // Make columns have equal width
    overflow: hidden; // Prevent overflow

    img {
      width: 100%;
      height: auto;
      display: block;
      margin-bottom: $padding-small;
    }
  }
}

.attention {
  background-color: $brand-attention;
}

input {
  padding: 0.5rem;
  width: 100%;
  border: none;
  background: lighten($color: $brand-secondary, $amount: 10%);
  color: $white;
  border-radius: 4px;
  &:focus {
    outline: none;
    box-shadow: 0 0 5px $brand-primary;
  }
}

#banner {
  background: #25D366;
  text-align: center;
}

header {
  background-color: $brand-secondary;
  color: $white;
  padding: 1rem 0;
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    #logo {
      max-width: 70%;

      img {
        height: auto;
      }
    }
    .header-call-to-action {
      @media screen and (max-width: $width-tablet) {
        max-width: 50%;
        width: 60px;
        height: 60px;
        .btn {
          display: block;
          padding: 15px;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          svg {
            width: 30px;
            height: auto;
            margin-right: 0 !important;
            display: block;
          }
        }
        .call-text {
          display: none;
        }
      }
      .btn {
        display: block;
        width: 100%;
        svg {
          margin-right: 0.5rem;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 $padding-medium;
}

.text-center {
  text-align: center;
}

#slider {
  position: relative;
  .glide__slide {
    position: relative;

    img {
      display: block;
      width: 100%;
      height: 800px;
      object-fit: cover; // Añade esto
      object-position: center; 
      @media screen and (max-width: $width-tablet) {
        height: 320px;
      }
      @media screen and (max-width: $width-mobile) {
        height: 280px;
      }
    }
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .form-container {
    position: absolute;
    top: 50%;
    border-radius: $border-radius;
    box-shadow: 1px 1px 5px $black;
    transform: translateY(-50%);
    right: $padding-medium;
    width: 35%;
    background: $brand-secondary;

    @media screen and (max-width: $width-tablet) {
      position: static;
      transform: none;
      display: block;
      width: 100%;
      border-radius: 0;
    }


    #form {
      width: 100%;
      
      padding: $padding-big;
      color: $white;
      h2, h3, h4 {
        color: $white;
        font-weight: 700;
      }
      p {
        margin-bottom: 1rem;
        a {
          color: inherit;
          font-weight: 700;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .form-group {
        margin-bottom: 1rem;
      }
      .btn {
        padding: 1rem 3rem;
      }
      .social-icons {
        a {
          color: $brand-attention;
          &:hover {
            color: $white;
          }
        }
      }
    }
  }

  .caption {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: $padding-medium;
    color: $white;
    max-width: 50%;
    h2, h3 {
      color: $white;
    }
    h3 {
      font-weight: 500;
      margin-bottom: 0.5rem;
    }
    text-transform: uppercase;
    text-shadow: 1px 1px 8px rgba(0, 0, 0, 1), 1px 1px 8px rgba(0, 0, 0, 1);

    h2 {
      font-size: 3rem;
      font-weight: 900;
      @media screen and (max-width: $width-tablet) {
        font-size: 2rem;
      }
      @media screen and (max-width: $width-mobile) {
        font-size: 1.5rem;
      }
    }

    h3 {
      font-size: 2rem;
      @media screen and (max-width: $width-tablet) {
        font-size: 1.5rem;
      }
      @media screen and (max-width: $width-mobile) {
        font-size: 1.2rem;
      }
    }

    @media screen and (max-width: $width-tablet) {
      top: auto;
      max-width: 100%;
      bottom: $padding-medium;
      transform: none;
      left: $padding-medium;
      right: $padding-medium;
      text-align: center;
    }
  }
}

#about {
  padding: $padding-big 0;
  img {
    border-radius: $border-radius;
  }
  p {
    margin-bottom: 1rem;
  }
}

#marcas {
  background: $brand-secondary;
  color: $white;
  padding: $padding-small 0;

  .marcas-content {
    text-align: center;
    display: flex;
    justify-content: center;
    svg {
      margin: $padding-small;
      display: inline-block;
      max-width: 100px;
    }
  }
}

#categories {
  background: $gray;
  h2 {
    color: $white;
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  color: $brand-secondary;
  padding: $padding-big 0;

  .categories-content {
    align-items: center;
    padding-top: $padding-small;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media screen and (max-width: $width-tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
    gap: $padding-medium;
    .category {
      position: relative;
      flex: 1;
      text-align: center;
      transition: transform 300ms ease;
      &:hover {
        transform: scale(1.1);
      }
      h3 {
        position: absolute;
        display: block;
        background: rgba(0, 0, 0, 0.5);
        color: $brand-secondary;
        padding: $padding-small;
        margin-bottom: 0;
        border-radius: 0 0 $border-radius $border-radius;
        bottom: 0;
        text-align: center;
        width: 100%;
        color: $white;
        font-size: 1.2rem;
        font-weight: 700;
      }

      picture {
        position: relative;
        margin: 0;
        padding: 0;
        img {
          display: block;
          border-radius: $border-radius;
        }
      }
    }
  }
}

.call-to-action {
  width: 100%;
  .btn {
    display: block;
    width: 100%;
  }
  
  padding: $padding-big 0;
}

#ventajas {
  padding: $padding-big 0;
  .ventajas-content {
    padding-top: $padding-small;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media screen and (max-width: $width-tablet) {
      grid-template-columns: repeat(1, 1fr);
    }
    .ventaja {
      display: flex;
      span {
        font-size: 1.25rem;
        text-transform: uppercase;
        color: darken($brand-secondary, 20%);
        padding-left: $padding-small;
      }

      svg {
        width: 80px;
        max-width: 100%;
        height: auto;
        color: darken($brand-secondary, 20%);
      }
    }
  }
}

#products {
  padding: $padding-big 0;
}

#footer {
  color: $white;
  background: $brand-secondary;
  .social-icons {
    padding: $padding-medium 0;
    text-align: center;
    svg {
      display: inline-block;
      padding: $padding-small;
    }
  }
  a {
    color: $brand-primary;
    font-weight: 700;
    &:hover {
      color: $white;
      text-transform: none;
    }
  }
  padding: $padding-medium 0 6rem 0;
  p {
    margin-bottom: 0.5rem;
    svg {
      display: inline-block;
      width: 20px;
      height: auto;
      margin-right: 0.5rem;
      margin-top: 0px;
      vertical-align: middle;
    }
  }
  h1, h2, h3, h4, h5 {
    color: $white;
  }
  .footer-bottom {
    padding-top: $padding-big;
  }
  .credits {
    padding: $padding-medium 0;
    font-size: 1rem;
  }
}

// CSS para el botón de WhatsApp flotante
.whatsapp-float {
  position: fixed;
  bottom: $padding-small;
  right: $padding-small;
  background-color: #25d366;
  color: #ffffff !important;
  border-radius: 50px;
  text-align: center;
  font-size: 1.5rem;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  padding: $padding-small $padding-small;
  display: flex;
  vertical-align: center;
  font-weight: normal;
  &:hover {
    background-color: darken(#25d366, 10%);
  }
  svg {
    color: $white;
    width: 40px;
    height: 40px;
    margin: 0 5px 0 0;
  }
}

.grecaptcha-badge {
  display: none;
}

.form-submit {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .btn {
    justify-self: start;
  }

  .recaptcha-text {
    justify-self: end;
    padding-left: $padding-small;
    font-size: 0.5rem;
    p {
      margin-bottom: 0 !important;
    }
  }
}

.youtube-video {
  height: 768px;
}