$glide-class: 'glide' !default;
$glide-element-separator: '__' !default;
$glide-modifier-separator: '--' !default;

.#{$glide-class} {
  $this: &;

  $se: $glide-element-separator;
  $sm: $glide-modifier-separator;

  position: relative;
  width: 100%;
  box-sizing: border-box;

  * {
    box-sizing: inherit;
  }

  &#{$se}track {
    overflow: hidden;
  }

  &#{$se}slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;

    &#{$glide-modifier-separator}dragging {
      user-select: none;
    }
  }

  &#{$se}slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;

    a {
      user-select: none;
      -webkit-user-drag: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }
  }

  &#{$se}arrows {
    -webkit-touch-callout: none;
    user-select: none;
  }

  &#{$se}bullets {
    -webkit-touch-callout: none;
    user-select: none;
  }

  &#{$sm}rtl {
    direction: rtl;
  }
}


.#{$glide-class} {
  $this: &;

  $se: $glide-element-separator;
  $sm: $glide-modifier-separator;

  &#{$se}arrow {
    position: absolute;
    display: block;
    top: 90%;
    z-index: 2;
    color: white;
    text-transform: uppercase;
    padding: 9px 12px;

    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    border: none;
    text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
    opacity: 1;
    cursor: pointer;
    transition: opacity 150ms ease, border 300ms ease-in-out;
    transform: translateY(-50%);
    line-height: 1;

    &:focus { outline: none; }
    &:hover { border: none; }

    &#{$sm}left {
      left: $padding-small;
    }

    &#{$sm}right {
      right: $padding-small;
    }

    &#{$sm}disabled {
      opacity: 0.33;
    }
  }

  &#{$se}bullets {
    position: absolute;
    z-index: 2;
    bottom: 2em;
    left: 50%;
    display: inline-flex;
    list-style: none;
    transform: translateX(-50%);
  }

  &#{$se}bullet {
    background-color: rgba(255, 255, 255, 0.5);
    width: 9px;
    height: 9px;
    padding: 0;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    line-height: 0;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
    margin: 0 0.25em;

    &:focus {
      outline: none;
    }

    &:hover,
    &:focus {
      border: 2px solid white;
      background-color: rgba(255, 255, 255, 0.5);
    }

    &#{$sm}active {
      background-color: white;
    }
  }

  &#{$sm}swipeable {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  &#{$sm}dragging {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}
